import React from "react";
import { keyframes, styled } from "styled-components";
import { useWindowSize } from "../../utils/useWindowSize";

function Orb() {

    const {width, height} = useWindowSize()

    const moveOrb = keyframes`
        0%{
            transform: translate(0, 0);
        }
        50%{
            transform: translate(${width}px, ${height/2}px);
        }
        100%{
            transform: translate(0, 0);
        }
    `
       

  const Orbstyled = styled.div`
    width: 70vh;
    height: 70vh;
    position: absolute;
    border-radius: 50%;
    margin-left: -37vh;
    margin-top: -37vh;
    background: linear-gradient(180deg, #f56692 0%, #f2994a 100%);
    filter: blur(250px);
   animation: ${moveOrb} 15s alternate linear infinite;
  `;
  return <Orbstyled></Orbstyled>;
}

export default Orb;
