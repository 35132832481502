import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import avatar from "../../img/logo.png";
import { menuItems } from "../../utils/menuItems";
import { signout } from "../../utils/Icons";
import { Link} from "react-router-dom";
function Navigation({ active, setActive }) {

  const [username,setusername]=useState("");
  useEffect(()=>{
      const obj=localStorage.getItem('user');
     if(obj){
      setusername(JSON.parse(obj).fullname);
     }

  });
  const handlesignout = () => {
    localStorage.removeItem("user");
  };

  return (
    <NavStyled>
      <div className="user-con">
        <img src={avatar} alt="" />
        <div className="text">
          <h2>ExIncome</h2>
          <p>{username}</p>
        </div>
      </div>
      <ul className="menu-items">
        {menuItems.map((item) => {
          return (
            <li
              key={item.id}
              onClick={() => setActive(item.id)}
              className={active === item.id ? "active" : ""}
            >
              {item.icon}
              <span>{item.title}</span>
            </li>
          );
        })}
      </ul>
      <div className="bottom-nav">
        <Link to='/signin' className="signout" onClick={handlesignout}>
          <span>{signout}</span>
          <span> Sign Out</span>
        </Link>
      </div>
    </NavStyled>
  );
}

const NavStyled = styled.div`
  .bottom-nav {
    padding: 10px;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
  }
  .signout{
    text-decoration:none;
  }
  padding: 2rem 1.5rem;
  width: auto;
  height: 100%;
  background: rgba(252, 246, 249, 0.78);
  border: 3px solid #ffffff;
  backdrop-filter: blur(4.5px);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  .user-con {
    height: 100px;
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      background: #fcf6f9;
      border: 2px solid #ffffff;
      padding: 0.2rem;
      box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.06);
    }
    h2 {
      color: rgba(34, 34, 96, 1);
    }
    p {
      color: rgba(34, 34, 96, 0.6);
    }
  }
  .menu-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    li {
      display: grid;
      grid-template-columns: 40px auto;
      align-items: center;
      margin: 0.6rem 0;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      color: rgba(34, 34, 96, 0.6);
      padding-left: 1rem;
      position: relative;
      i {
        color: rgba(34, 34, 96, 0.6);
        font-size: 1.4rem;
        transition: all 0.4s ease-in-out;
      }
    }
  }

  .active {
    color: rgba(34, 34, 96, 1) !important;
    i {
      color: rgba(34, 34, 96, 1) !important;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: #222260;
      border-radius: 0 10px 10px 0;
    }
  }
`;

export default Navigation;
