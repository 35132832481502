import { InnerLayout } from "../../styles/Layouts";
import React, { useEffect } from "react";
import styled from "styled-components";
import { dollar } from "../../utils/Icons";
import { useGlobalContext } from "../../context/ContextAPI";
import Chart from "../Charts/Chart";
import History from "../History/History";
import { useNavigate } from "react-router-dom";
function Dashboard() {
  const {
    totalExpenses,
    incomes,
    expenses,
    totalIncome,
    totalBalance,
    getIncomes,
    getExpenses,
  } = useGlobalContext();
  const navigate=useNavigate();
  useEffect(()=>{
    const token = localStorage.getItem("user");
    if (token == null) {
      navigate("/signin");
    }else{

    }
  })

  useEffect(() => {

    getIncomes();
    getExpenses();
  }, []);

  return (
    <div>
      <DashboardStyled>
        <InnerLayout>
          <h1>All Transactions</h1>
          <div className="stats-con">
            <div className="chart-con">
              <Chart />

              <div className="amount-con">
                <div className="income">
                  <h2>Total Income</h2>
                  <p>
                    {dollar} {totalIncome()}
                  </p>
                </div>
                <div className="expense">
                  <h2>Total Expense</h2>
                  <p>
                    {dollar} {totalExpenses()}
                  </p>
                </div>
                <div className="balance">
                  <h2>Total Balance</h2>
                  <p>
                    {dollar} {totalBalance()}
                  </p>
                </div>
              </div>
            </div>
            <div className="history-con">
              <History />
              <h2 className="salary-title">
                Min <span>Salary</span>Max
              </h2>
              <div className="salary-item">
                <p>${Math.min(...incomes.map((item) => item.amount))}</p>
                <p>${Math.max(...incomes.map((item) => item.amount))}</p>
              </div>
              <h2 className="salary-title">
                Min <span>Expense</span>Max
              </h2>
              <div className="salary-item">
                <p>${Math.min(...expenses.map((item) => item.amount))}</p>
                <p>${Math.max(...expenses.map((item) => item.amount))}</p>
              </div>
            </div>
          </div>
        </InnerLayout>
      </DashboardStyled>
    </div>
  );
}

const DashboardStyled = styled.div`
  width: 75vw;
  height:120vh;
  background: rgba(252, 246, 249, 0.78);
  border: 3px solid #ffffff;
  backdrop-filter: blur(4.5px);
  border-radius: 32px;
  display: flex;
  // overflow: auto;
  margin-bottom: 2rem;
  .stats-con {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    height: 82vh;
    .chart-con {
      grid-column: 1 / 4;
      height: 400px;
      .amount-con {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
        margin-top: 2rem;
        .income,
        .expense {
          grid-column: span 2;
        }
        .income,
        .expense,
        .balance {
          background: #fcf6f9;
          border: 2px solid #ffffff;
          box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
          border-radius: 20px;
          padding: 1rem;
          p {
            font-size: 3.5rem;
            font-weight: 700;
          }
        }

        .balance {
          grid-column: 2 / 4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            color: var(--color-green);
            opacity: 0.6;
            font-size: 4rem;
          }
        }
      }
    }

    .history-con {
      grid-column: 4 / -1;
      h2 {
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .salary-title {
        font-size: 1.2rem;
        span {
          font-size: 1.8rem;
        }
      }
      .salary-item {
        background: #fcf6f9;
        border: 2px solid #ffffff;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        padding: 1rem;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-weight: 600;
          font-size: 1.6rem;
        }
      }
    }
  }
`;

export default Dashboard;
